import React, { useContext } from "react";
import Router from "next/router";
import Link from "next/link";
import { SubscriptionContext } from "../../services/SubscriptionService";
import { DEFAULT_SPORT } from "../../constants";
import LandingPageModal from "../LandingPageModal";
import styles from "./styles.scss";
import MarketingVideo from "src/components/MarketingVideo";
import VideoGallery from "src/components/VideoGallery";
import FocusedTextLink from "../FocusedTextLink";
import { TAGLINE_2, TAGLINE_3 } from "src/services/DisplayService";
import { useFreeTrialClaimed } from "src/services/UserService";

class CTAButton extends React.Component<any> {
  static contextType = SubscriptionContext;

  modal: any = {};
  setModal = (api: any) => (this.modal = api);

  onClick = () => {
    this.props.onClick?.();
    const { signedIn } = this.context;
    if (signedIn) {
      Router.push(`/games?sport=${DEFAULT_SPORT.url}&page=odds`, `/${DEFAULT_SPORT.url}/odds`);
    } else {
      this.modal.open();
    }
  };

  render() {
    const { children = "Get Started for Free", className, style } = this.props;
    return (
      <FocusedTextLink className={className} style={style} onClick={this.onClick}>
        {children}
        <LandingPageModal api={this.setModal} />
      </FocusedTextLink>
    );
  }
}

function Hero() {
  const ctx = useContext(SubscriptionContext);
  const trialClaimed = useFreeTrialClaimed();

  const signedOutMarkup = (
    // NOTE styles for .hero and .headline also used in /free-picks
    <div className={`hero ${ctx.signedIn ? "signed-in" : ""}`}>
      <p className="headline tagline_3">{TAGLINE_3}</p>
      <div className="links">
        <CTAButton className="button" style={{ backgroundColor: "#14F2B3", color: "#00214D" }} />
        <Link href="/how-it-works" passHref legacyBehavior>
          <a className="button" style={{ backgroundColor: "#EAEFF4", color: "#00214D" }}>
            Learn More
          </a>
        </Link>
      </div>
      <MarketingVideo />
    </div>
  );

  const signedInMarkup = (
    <div className={`video-hero ${ctx.signedIn ? "signed-in" : ""}`}>
      <div className="cta-section">
        <h1 className="headline">{TAGLINE_2}</h1>
        <div className="links">
          <CTAButton className="button" style={{ backgroundColor: "#14F2B3", color: "#00214D" }}>
            View Best Bets
          </CTAButton>
          <Link href="/pricing/annual" legacyBehavior>
            <a className="button" style={{ backgroundColor: "#EAEFF4", color: "#00214D" }}>
              {trialClaimed ? "Upgrade Now" : "Start Free Trial"}
            </a>
          </Link>
        </div>
        <MarketingVideo />
      </div>
      <div style={{ flex: 1, minWidth: 40, maxWidth: 90, minHeight: 24 }} />
      <VideoGallery />
    </div>
  );

  return (
    <section aria-label="Hero">
      {signedInMarkup}
      {signedOutMarkup}
      <style jsx>{styles}</style>
    </section>
  );
}

export default Hero;
