import { gql } from "@apollo/client";

export const VIDEO_PLAYLIST_WITH_LIVE = gql`
  query videoPlaylistWithLive($playlistId: String!, $videoId: String!) {
    dailymotionVideo(videoId: $videoId) {
      id
      title
      description
      thumbnail_url
      created_time
    }
    dailymotionPlaylist(playlistId: $playlistId) {
      id
      title
      description
      thumbnail_url
      created_time
    }
  }
`;
