import React, { Component } from "react";
import { Modal } from "reactstrap";
import { mdiClose } from "@mdi/js";
import Icon from "@mdi/react";

import styles from "./styles.scss";
import Register from "../LoginModal/Register";

export interface LandingPageModalApi {
  open: () => any;
}

interface LandingPageModalProps {
  api: (api: LandingPageModalApi) => void;
}

interface LandingPageModalState {
  toggle: boolean;
  selectedStateIndex: number;
  email: string;
  sport: string;
  formErrorList: string[];
}

class LandingPageModal extends Component<LandingPageModalProps, LandingPageModalState> {
  constructor(props: any) {
    super(props);

    this.state = {
      toggle: false,
      selectedStateIndex: 0,
      email: "",
      sport: "",
      formErrorList: [],
    };

    // Usage: <Parent api={this.setModalApi} />
    props.api({
      open: () => this.setState({ toggle: true }),
    });
  }

  toggleModal = () => {
    this.setState({ toggle: !this.state.toggle });
  };

  render() {
    const { toggle } = this.state;
    return (
      <Modal isOpen={toggle} toggle={() => this.toggleModal} className="landing-page-modal">
        <div className="landing-page-modal__container">
          <div className="landing-page-modal__close-button" onClick={this.toggleModal}>
            <Icon path={mdiClose} size="22px" color="#fff" />
          </div>
          <div className="landing-page-modal__title">
            Get Today's Best Bets
            <br />
            and Start Betting like a Pro
          </div>
          <div className="landing-page-modal__subtitle">Fill Out Form to Get Today's Best Bets</div>
          <Register showSports />
        </div>
        <style jsx global>
          {styles}
        </style>
      </Modal>
    );
  }
}

export default LandingPageModal;
