import React, { useState, useRef, useContext } from "react";
import styles from "./styles.scss";
import { useContainerQuery } from "src/services/UtilService";
import { SubscriptionContext } from "src/services/SubscriptionService";
import FocusedTextLink from "../FocusedTextLink";
import { useQuery } from "@apollo/client";
import { VIDEO_PLAYLIST_WITH_LIVE } from "src/gql/queries/video";

export const DAILYMOTION_PLAYER_ID = "xsb3c";
const LIVE_VIDEO_ID = "x8y77yq";
export const DAILYMOTION_PLAYLIST_ID = "x8d07o";

type DailymotionSource = {
  id: string;
  title: string;
  created_time: string;
  description: string;
  thumbnail_url: string;
};

type DailymotionResult = {
  dailymotionPlaylist: DailymotionSource[];
  dailymotionVideo: DailymotionSource;
};

export default function VideoStream() {
  const { data } = useQuery<DailymotionResult>(VIDEO_PLAYLIST_WITH_LIVE, {
    variables: { playlistId: DAILYMOTION_PLAYLIST_ID, videoId: LIVE_VIDEO_ID },
  });

  const ctx = useContext(SubscriptionContext);
  const container = useRef<any>(null);
  const [currentVideo, setCurrentVideo] = useState(LIVE_VIDEO_ID);

  useContainerQuery(container, {
    small: 700, // check all usages if changing this
    medium: 1000,
    large: Infinity,
  });

  const dailymotionPlaylistVideos = [...(data?.dailymotionPlaylist ?? [])];

  if (data?.dailymotionVideo) {
    dailymotionPlaylistVideos?.unshift(data.dailymotionVideo);
  }

  return (
    <div className="video-gallery" ref={container}>
      <div style={{ position: "relative", paddingBottom: "56.25%" }}>
        <iframe
          key={`${ctx?.signedIn}`}
          frameBorder={0}
          width="100%"
          height="100%"
          src={`https://geo.dailymotion.com/player/${DAILYMOTION_PLAYER_ID}.html?video=${currentVideo}&playlist=${DAILYMOTION_PLAYLIST_ID}`}
          allowFullScreen
          allow="autoplay; fullscreen; picture-in-picture; web-share"
          title="BetQL Audio Network."
          className="player"
        />
      </div>

      <div className="sidebar">
        <h2>Recent Videos</h2>
        <div className="playlist">
          {dailymotionPlaylistVideos?.map((track, index: number) => (
            <FocusedTextLink
              className="card"
              key={index}
              onClick={() => {
                setCurrentVideo(track.id);
              }}
            >
              {track?.thumbnail_url ? (
                <img className="thumbnail lazyload" data-src={track.thumbnail_url} alt={track?.title} />
              ) : (
                <div className="livePlaceholder">
                  <img src="/livestream-thumbnail.jpg" />
                </div>
              )}
              <div className="label">{track.title}</div>
            </FocusedTextLink>
          )) ?? null}
        </div>
      </div>
      <style jsx>{styles}</style>
    </div>
  );
}
