import React, { useState, useRef, useEffect } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTimes } from "@fortawesome/free-solid-svg-icons";
import { faPlayCircle } from "@fortawesome/free-regular-svg-icons";
import YouTube from "react-youtube";
import styles from "./styles.scss";
import { usePortal } from "../Portal";
import GAService from "src/services/GAService";
import AnalyticsService from "src/services/AnalyticsService";
import FocusedTextLink from "../FocusedTextLink";

// previous video_id: "wo_OM-52xks"
const MARKETING_VIDEO_YOUTUBE_ID = "agNkjumFVCI";

const trackPlay = () => {
  GAService.sendEvent({
    hitType: "event",
    eventCategory: "Play Marketing Video",
    eventAction: window.location.href,
    eventLabel: "Play Marketing Video",
  });

  AnalyticsService.track("Play Marketing Video", {
    screen: window.location.href,
  });
};

// Link with popup video
const MarketingVideo = ({ className = "", ...other }: any) => {
  const videoRef = useRef<any>();

  const handleModalClick = (e: any) => {
    const validClickTargets = ["marketing-video-modal", "close"];
    if (!validClickTargets.some((x: string) => e.nativeEvent.target.classList.contains(x))) {
      return;
    } // ignore video click
    setModalVisible(false);
  };

  const [modalVisible, setModalVisible] = usePortal(
    <div
      className="marketing-video-modal" // see above for className check
      onClick={handleModalClick}
    >
      <div className="close">
        <FontAwesomeIcon icon={faTimes} style={{ pointerEvents: "none" }} />
      </div>
      <div className="marketing-video-player-wrapper">
        <YouTube
          className="player"
          videoId={MARKETING_VIDEO_YOUTUBE_ID}
          // Note its stuck at 360 or 480 not sure why
          opts={{
            width: "1280",
            height: "720",
            playerVars: { rel: 0, vq: "hd720" } as any,
          }}
          ref={videoRef}
        />
      </div>
    </div>
  );

  const handleLinkClick = () => {
    setModalVisible(true);
    trackPlay();
  };

  useEffect(() => {
    if (modalVisible) {
      setTimeout(() => {
        videoRef.current.internalPlayer.playVideo();
      }, 0);
    }
  }, [modalVisible]);

  return (
    <React.Fragment>
      <FocusedTextLink className={`marketing-video-link ${className}`} onClick={handleLinkClick} {...other}>
        Watch Video <FontAwesomeIcon icon={faPlayCircle} />
        <style jsx global>
          {styles}
        </style>
      </FocusedTextLink>
    </React.Fragment>
  );
};

// Inline video element embed
export const MarketingVideoEmbed = () => {
  const [overlayVisible, setOverlayVisible] = useState(true);
  const videoRef = useRef<any>();

  const handleLinkClick = () => {
    setOverlayVisible(false);
    videoRef.current.internalPlayer.playVideo();
    trackPlay();
  };

  return (
    <span className="marketing-video-embed-component">
      <YouTube
        className="player"
        videoId={MARKETING_VIDEO_YOUTUBE_ID}
        opts={{
          width: "1280",
          height: "720",
          playerVars: { rel: 0, vq: "hd720" } as any,
        }}
        ref={videoRef}
      />
      <div className="overlay" onClick={handleLinkClick} style={{ visibility: overlayVisible ? undefined : "hidden" }}>
        <FontAwesomeIcon icon={faPlayCircle} style={{ fontSize: 72 }} />
        <span style={{ marginTop: 15 }}>Watch Our Video</span>
      </div>
      <style jsx global>
        {styles}
      </style>
    </span>
  );
};

export default MarketingVideo;
